import React, { memo } from 'react'
import Layout from '../layouts/Layout'

const Learn = props => {
  return (
    <Layout canonicalURL='' title='' pageTitle='' description=''>

    </Layout>
  )
}

export default memo(Learn)